import React from "react";
import Layout from "../components/layout";
import Section from "../components/section";
import SubTitle from "../components/subtitle";
import PhotoImage from "../components/photo-image";
import styled from "styled-components";
import { classNames } from "../util/Util";

const images: {
  caption: string;
  src: string;
}[] = [
  {
    caption: "診療スペース",
    src: require("../images/photos/facility/shinryou_space.jpg"),
  },
  {
    caption: "聴覚検査室",
    src: require("../images/photos/facility/chokaku_kensa_shitsu.jpg"),
  },
  {
    caption: "点滴コーナー",
    src: require("../images/photos/facility/tenteki_corner.jpg"),
  },
  {
    caption: "レントゲンCT室",
    src: require("../images/photos/facility/x_ray_kensashitsu.jpg"),
  },
  {
    caption: "ネブライザーコーナー",
    src: require("../images/photos/facility/nebulizer.jpg"),
  },
  {
    caption: "処置室",
    src: require("../images/photos/facility/shochisitsu.jpg"),
  },
];

const BIG_WIDTH = 800;

const PhotoLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 40px;
  .photo {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 300px;
    padding: 24px 16px;
  }
  .photo__big {
    flex-basis: ${BIG_WIDTH}px;
    @media screen and (max-width: ${BIG_WIDTH}px) {
      flex-basis: 300px;
    }
  }
`;

const FacilityPage = () => (
  <Layout>
    <Section>
      <SubTitle>院内設備のご紹介</SubTitle>
      <PhotoLayout>
        {images.map((image, index) => {
          return (
            <PhotoImage
              src={image.src}
              caption={image.caption}
              wrapperClassName={classNames({
                photo__big: index === 0,
                photo: true,
              })}
              key={index}
            />
          );
        })}
      </PhotoLayout>
    </Section>
  </Layout>
);

export default () => <FacilityPage />;
