import React from "react";
import styled from "styled-components";

const Figure = styled.figcaption``;
const Figcaption = styled.figcaption`
  text-align: center;
`;
const PhotoImage = ({
  src,
  wrapperClassName,
  caption,
  description
}: {
  src: string;
  wrapperClassName?: string;
  caption: string;
  description?: string;
}) => {
  return (
    <Figure className={wrapperClassName}>
      <img src={src} alt={caption} />
      <Figcaption>{caption}</Figcaption>
      {description ? (
        <div
          style={{
            textAlign: "center",
            fontSize: 14
          }}
        >
          {description}
        </div>
      ) : null}
    </Figure>
  );
};

export default PhotoImage;
