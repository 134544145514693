import React from 'react'
import styled from 'styled-components'

const H2 = styled.h2`
margin: 8px 0 16px;
`

const SubTitle = ({ children }: { children: any | any[] }) => (
  <H2>{children}</H2>
)

export default SubTitle